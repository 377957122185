import React from 'react'

const Footer = () => {
  return (
    <>
      <div className="footer">
        <h4>© 2020 Godspeed | All rights reserved.</h4>
      </div>
    </>
  )
}

export default Footer
