import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/docs/src/components/layouts/code-block.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`<Button text="Open Drawer" onClick={() => setDrawer(true)} />
<Drawer onClick={() => setDrawer(false)} open={drawer}>
  <h1>Title</h1>
  <p>This is some placeholder content</p>
</Drawer>
<br />
<Button text="Open Drawer" onClick={() => setDrawer(true)} />
<Drawer onClick={() => setDrawer(false)} open={drawer} bg="black" color="white">
  <h1>Title</h1>
  <p>This is some placeholder content</p>
</Drawer>
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      